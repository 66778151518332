import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import './Asstes/sass/style.scss';
import Header from './Components/Header';
import { Route, Routes, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { routes } from './Routes/routes';
import Footer from './Components/Footer';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { SERVER_URL, frontEnd_API, header, storage, storeCode } from './Config/config';
import StoreNotFound from './Components/StoreNotFound';
import StoreUnderMaintenance from './Components/StoreUnderMaintenance';
import { useDispatch, useSelector } from 'react-redux';
import { addStore, removeStore } from './Store/Slices/storeSlice';
import Loader from './Components/Loader';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { storeLoader } from './Store/Slices/homeLoader';

function App() {

  const refCode = parseInt(new URLSearchParams(window.location.search)?.get('ref')?? 0)
  console.log("refCode :: ", refCode)

  const [dataFound, setDataFound] = useState(storeCode?.length > 0);
  console.log("storeCode?.length > 0 ? 1 : 0 :: ", storeCode?.length > 0 ? 1 : 0)
  const [storeCurrentStatus, setStoreCurrentStatus] = useState(storeCode?.length > 0 ? 1 : 0);
  const [loader, setLoader] = useState(storeCode?.length <= 0);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [theme, setTheme] = useState();
  const user = useSelector((state) => state.user.value);
  const store = useSelector((state) => state.store.value);
  const localLoader = useSelector((state) => state.loader.value);

  console.log("storeCurrentStatus :: ", storeCurrentStatus)

  // function for manage snapstore -----
  const verifySnapStore = async (obj = {from: null}) => {
    try {
      // const domain = 'grow.printfuse.in';
      const domain = ["localhost", "192.168.0.107"].includes(window.location.hostname) ? 'first.printfuse.in' : window.location.hostname
      const currentStoreCode = ([null, '', undefined]?.includes(obj?.from)) ? storeCode : ''

      const { data } = await axios?.post(frontEnd_API?.verify, { domain, storeCode:currentStoreCode }, header);

      const status = data?.status?? 0
      const object = data?.data?? {}

      if (status == -3) {
        localStorage?.removeItem(storage?.cart)
        localStorage?.removeItem(storage?.couponCode)
        localStorage?.removeItem(storage?.store)
        localStorage?.removeItem(storage?.user)
        localStorage?.removeItem(storage?.loader)
      }

      if (data?.status != 1) setStoreCurrentStatus(data?.status?? 0)
      console.log("data 41 :: ", data?.data)
      
      if (data?.status == 1) {
        dispatch(addStore(object))
        setTheme(object?.currentTheme)
        document.title = object?.name?.split(' ')?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(' ');
        if (currentStoreCode?.length<= 0)
          setTimeout(() => {
            
            if (refCode == 1) {
              window.location.reload()
            } else {
              window.location = ["localhost", "192.168.0.107"].includes(window.location.hostname) ? 'http://localhost:3001/' : `https://${window.location.hostname}/`
            }
          }, 750)
      }
    } catch (error) {
      setStoreCurrentStatus(0)
      const { data, status } = error?.response
      console.log("data error :: ", data)
      console.log("status error  :: ", status)
    }
  }
  // -----
  console.log("storeCurrentStatus : ", storeCurrentStatus)
  useEffect(() => {
    // axios.post(frontEnd_API.verify, { domain: "rushi-shop.printfuse.in" }, header)
    // axios.post(frontEnd_API.verify, { domain: (["localhost", "192.168.0.107"].includes(window.location.hostname)) ? 'grow.printfuse.in' : window?.location?.hostname }, header)
    verifySnapStore()
  }, [])

  useEffect(() => {
    if (!user) {
      if (location.pathname.startsWith('/user')) {
        navigate('/');
      }
    }
  }, [location.pathname, navigate, user])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const showHeader = location.pathname.endsWith('/checkout');

  return (
    <div className={`custom-align-page ${(theme) ? theme : 'theme-one'} ${[-3, -2]?.includes(storeCurrentStatus) ? 'custom-align-page theme-one d-block h-100 d-flex align-items-center justify-content-center' : ''}`}>
      { [-3, -2]?.includes(storeCurrentStatus) && (
        <StoreUnderMaintenance
          code={storeCurrentStatus}
          onClick={(val) => verifySnapStore({from:val})}
        />) }
      { storeCurrentStatus == -1 && (<StoreNotFound />) }
      { storeCurrentStatus == 0 && (<Loader />) }
      {
        storeCurrentStatus == 1 && (
          <>
            <div>
              <Helmet>
                <link rel="icon" type="image/png" href={store?.logo?? require('./Asstes/Images/try-logo.png')} />
                <title>{store?.name?? 'Snap Store'}</title>
                <meta name="description" content={store?.shortDescription?? 'Printfuse snap store'} />
              </Helmet>
              {(!showHeader) && <Header />}
              <Routes>
                { routes.map((item, index) => <Route key={index} path={item.path} element={item.element} />) }
              </Routes>
            </div>
            {(!showHeader) && <Footer />}
          </>
        )
      }
      <ToastContainer />
    </div >
  );
}

export default App;
