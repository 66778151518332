import CartPage from "../View/Cart/CartPage";
import CheckOutPage from "../View/Checkout/CheckOutPage";
import Home from "../View/Home/Home";
import IntellectualPolicy from "../View/Pages/IntellectualPolicy";
import Pages from "../View/Pages/Pages";
import PrivacyPolicy from "../View/Pages/PrivacyPolicy";
import ReturnPolicy from "../View/Pages/ReturnPolicy";
import TermsOfServices from "../View/Pages/TermsOfServices";
import SingleProductPage from "../View/Product/SingleProductPage";
import Index from "../View/UserPanel/Index";

export const routes = [
    {
        path: '/',
        element: <Home />
    },
    {
        path: '/category/:categoryName',
        element: <Home />
    },
    {
        path: '/home',
        element: <Home />
    },
    {
        path: '/cart',
        element: <CartPage />
    },
    {
        path: '/checkout',
        element: <CheckOutPage />
    },
    {
        path: '/user/*',
        element: <Index />
    },
    {
        path: '/product/:productId',
        element: <SingleProductPage />
    },
    {
        path: '/pages/:pageName',
        element: <Pages />
    },
    {
        path: '/terms',
        element: <TermsOfServices />
    },
    {
        path: '/privacy',
        element: <PrivacyPolicy />
    },
    {
        path: '/ippolicy',
        element: <IntellectualPolicy />
    },
    {
        path: '/return',
        element: <ReturnPolicy />
    },
]

