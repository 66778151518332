import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { storeCode, token } from '../Config/config';
import { IMask } from 'react-imask';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function FormComponent(props = {}) {

    const [formData, setFormData] = useState({});
    const [error, setError] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showEye, setShowEye] = useState(false);

    useEffect(() => {
        setFormData((props.valueData) ? props.valueData : {})
    }, [props.valueData])

    const handleChange = (item) => {

        delete error[item?.key];

        // For Chacking Required value
        if (item?.pattern || item?.required) {
            if (item?.required && item?.value?.length <= 0) {
                error[item?.key] = `${item?.name} is required`
            }

            if (item?.pattern && item?.value?.length > 0) {
                const patternCheck = new RegExp(item?.pattern);
                if (!patternCheck.test(item?.value))
                    error[item?.key] = `Invalid pattern for ${item?.name}`;
            }
        }

        // Store Data in formData
        const { key, value } = item
        setFormData((prevData) => ({
            ...prevData,
            [key]: value
        }))

        setError({ ...error });

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const fileCheck = props.fields.find((data) => data.type === 'file');
        const header = {
            headers: {
                'token': token,
                'storecode': storeCode,
                'Content-Type': (fileCheck) ? 'multipart/form-data' : 'application/json'
            }
        }
        const actionMethod = props?.actionType || 'post';
        const actionUrl = props.action + `${props.actionValue ? `/${props.actionValue}` : ''}`;
        axios[actionMethod](actionUrl, formData, header)
            .then((res) => {
                console.log(res?.data);
                if (props?.onSubmit) {
                    props?.onSubmit(res?.data)
                }
                if (props?.dispatchRedux) {
                    dispatch(props?.dispatchRedux(res?.data?.data));
                }
                toast(props?.formMessage ?? 'Form Submitted Successfully !')
                setFormData({});
                if (props?.navigateURL) {
                    navigate(props?.navigateURL);
                    if (props?.loadPage) {
                        window.location.reload();
                    }
                }
                else {
                    if (props?.loadPage) {
                        window.location.reload();
                    }
                }
            })
            .catch((err) => {
                console.log("err::", err);
                if (err?.response?.data?.data?.non_field_message) {
                    toast(err?.response?.data?.data?.non_field_message)
                }
                else {
                    setError(err?.response?.data?.data);
                }
            })
    }

    useEffect(() => {
        console.log(formData);
    }, [formData])

    const renderValues = (val) => {

        const check = val?.options?.find((e) => e.value === val?.value);
        let checkCity = props?.fields?.find((e) => e.name === val?.to);

        checkCity.options = (parseInt(val?.value) !== 0) ? check[val?.from] : [];

        if (formData[val.to]) {
            setFormData((data) => ({
                ...data,
                [val.to]: ''
            }))
        }

    }

    const inputRefs = useRef({});

    useEffect(() => {
        // Apply IMask to input fields with patterns
        props.fields.forEach(field => {
            if (field.mask) {
                const maskOptions = {
                    mask: field.mask
                };
                const inputElement = inputRefs.current[field.name];
                if (inputElement) {
                    IMask(inputElement, maskOptions);
                }
            }
        });
    }, [props.fields]);

    const toggleEye = (field) => {
        setShowEye((prev) => ({
            ...prev,
            [field]: !prev[field]
        }));
    }

    return (
        <div>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Row className='g-2'>
                    {
                        props.fields.map((item, index) => {
                            const type = item?.type ?? 'text';

                            return (
                                <Col className={`px-1 d-grid align-content-start ${(item?.divClass)??'col-12'}`} key={index}>
                                    <label htmlFor={item?.key} className='fs-sm-14 text-capitalize'>{item?.name}<span className={(item.type !== 'checkbox' ? 'text-danger' : 'd-none')}>{(item?.required) && '*'}</span> </label>
                                    {
                                        (['select']?.includes(type))
                                        &&
                                        <>
                                            <select
                                                id={item?.key}
                                                className={`text-capitalize ${(item?.type !== 'checkbox') && 'w-100'} p-2 fs-sm-14 seller-box ${(error[item?.key]) && 'border-danger'}`}
                                                name={item?.name}
                                                value={formData[item?.key] ? formData[item?.key] : 0}
                                                onChange={(e) => {
                                                    if (item?.onChange) {
                                                        renderValues({ ...item?.onChange, value: e.target.value });
                                                    }
                                                    handleChange({ key: item?.name, value: e.target.value, required: item?.required })
                                                }}
                                                required={item?.required}
                                            >
                                                <option value={0}>Select {item?.name}</option>
                                                {
                                                    item?.options?.map((opt, subIndex) => {
                                                        return (
                                                            <option className='text-capitalize' value={opt.value} key={subIndex}>{opt.label}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </>
                                    }
                                    {
                                        (['text', 'checkbox', 'link', 'email', 'number', 'password']?.includes(type))
                                        &&
                                        <span className={(item.type === 'checkbox') ? 'd-flex align-items-start justify-content-start gap-2' : `${(item?.key === 'mobile') && 'custom-remove-number'} d-block custom-password-input`}>
                                            <input
                                                type={(item?.type) ? (item?.type !== 'password') ? item?.type : (showEye[item.key]) ? 'text' : 'password' : 'text'}
                                                id={item?.key}
                                                placeholder={item?.placeholder ? item?.placeholder : `enter ${item?.name}`}
                                                className={`${(item?.type !== 'checkbox') && 'w-100'} p-2 fs-sm-14 seller-box ${(error[item?.key]) && 'border-danger'}`}
                                                name={item?.name}
                                                value={formData[item?.key] ? formData[item?.key] : ''}
                                                ref={el => inputRefs.current[item?.name] = el}

                                                onChange={(e) => handleChange(
                                                    {
                                                        name: item?.name,
                                                        key: item?.key,
                                                        value: e.target?.value,
                                                        pattern: item?.pattern,
                                                        required: item?.required
                                                    }
                                                )}
                                                onBlur={(e) => handleChange(
                                                    {
                                                        name: item?.name,
                                                        key: item?.key,
                                                        value: e.target?.value,
                                                        pattern: item?.pattern,
                                                        required: item?.required
                                                    }
                                                )}
                                                autoComplete='off'
                                                required={item?.required}
                                            />
                                            <span className={`custom-eye ${(item.type !== 'password') && 'd-none'}`}>
                                                <i className={`bi fs-18 ${(!showEye[item.key]) ? 'bi-eye-slash' : "bi-eye"}`} onClick={() => toggleEye(item.key)}></i>
                                            </span>
                                            <label htmlFor={item.key} className={(item?.type !== 'checkbox' ? 'd-none' : `${item.checkCSS} w-100`)}>{item?.checkLabel}</label>
                                        </span>
                                    }
                                    {
                                        (['textarea']?.includes(type))
                                        && <textarea
                                            id={item.key}
                                            rows={item.areaRow}
                                            value={formData[item?.key] && formData[item?.key]}
                                            placeholder={item?.placeholder ? item.placeholder : `enter ${item?.name}`}
                                            className={`${(item?.type !== 'checkbox') && 'w-100'} p-2 fs-sm-14 seller-box ${(error[item?.key]) && 'border-danger'}`}
                                            name={item?.name}
                                            ref={el => inputRefs.current[item?.name] = el}
                                            onChange={(e) => handleChange(
                                                {
                                                    name: item?.name,
                                                    key: item?.key,
                                                    value: e.target.value,
                                                    pattern: item?.pattern,
                                                    required: item?.required
                                                }
                                            )}
                                            onBlur={(e) => handleChange(
                                                {
                                                    name: item?.name,
                                                    key: item?.key,
                                                    value: e.target.value,
                                                    pattern: item?.pattern,
                                                    required: item?.required
                                                }
                                            )}
                                            autoComplete='off'
                                            required={item?.required}
                                        />
                                    }
                                    <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error[item?.key]}</span>
                                </Col>
                            )
                        })
                    }
                    <Col lg='12' md='12' className="mt-3">
                        <div className={`text-start d-flex ${props?.submitBTNAlign}`}>
                            <Button color="info" className={`custom-submit-btn ${props?.buttonClass ?? ''} ${props?.submitFullWidth && 'w-100'} btn fw-semibold info-btn text-capitalize`} type="submit" disabled={Object?.keys(error).length !== 0}>
                                {props?.button ?? 'Submit'}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default FormComponent