const SERVER_URL = window.location.hostname.endsWith("printfuse.in") ? "https://api.printfuse.in/" : "http://192.168.0.107:4000/";
// const SERVER_URL = "https://api.printfuse.in/";
const API_BASE_URL = `${SERVER_URL}`;
const STORE_API_URL = `${API_BASE_URL}snapstore/`;

const frontEnd_API = {
    verify: `${STORE_API_URL}home/verify`,
    signin: `${STORE_API_URL}auth/signin`,
    signup: `${STORE_API_URL}auth/signup`,
    signout: `${STORE_API_URL}auth/signout`,
    getProfile: `${STORE_API_URL}auth/profile`,
    postUpdateProfile: `${STORE_API_URL}auth/profile`,
    sendotp: `${STORE_API_URL}auth/sendotp`,
    resendotp: `${STORE_API_URL}auth/resendotp`,
    verifyotp: `${STORE_API_URL}auth/verifyotp`,
    postUpdatePassword: `${STORE_API_URL}auth/changepassword`,
    getGlobal: `${STORE_API_URL}home/global`,
    getCategory: `${STORE_API_URL}home/category`,
    postAddress: `${STORE_API_URL}address`,
    getAddress: `${STORE_API_URL}address`,
    deleteAddress: `${STORE_API_URL}address`,
    primaryAddress: `${STORE_API_URL}address/primary`,
    getProducts: `${STORE_API_URL}product/list`,
    getProductDetail: `${STORE_API_URL}product/detail`,
    getCartList: `${STORE_API_URL}coupon/list`,
    postOrder: `${STORE_API_URL}order`,
    getOrderList: `${STORE_API_URL}order`,
    counttax: `${STORE_API_URL}order/counttax`,
    getPagesList: `${STORE_API_URL}pages`,
    returnorder: `${STORE_API_URL}returnorder`,
}

const storage = {
    store: 'store',
    user: 'user',
    cart: 'cart',
    loader: 'loader',
    couponCode: 'couponCode',
    tempClick: 'tempClick',
}

// const token = JSON.parse(localStorage?.getItem(storage.store))
//     ? `Bearer ${JSON.parse(localStorage?.getItem(storage.store))?.storeCode}`
//     : 'essentials';

const token = JSON.parse(localStorage?.getItem(storage.user))
    ? `Bearer ${JSON.parse(localStorage?.getItem(storage.user))?.token}`
    : 'essentials';

const storeCode = JSON.parse(localStorage?.getItem(storage.store))
    ? `${JSON.parse(localStorage?.getItem(storage.store))?.storeCode}`
    : '';


const webName = JSON.parse(localStorage?.getItem(storage.store))
    && `${JSON.parse(localStorage?.getItem(storage.store))?.name}`;

const header = {
    headers: {
        'token': token,
        'storecode': storeCode,
        'Content-Type': 'application/json'
    }
}

export { SERVER_URL, frontEnd_API, storeCode, token, header, storage, webName };