import React, { useEffect, useState } from 'react'
import { Alert, Col, Row, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { TbFileInvoice, TbWalletOff } from "react-icons/tb";
import axios from 'axios';
import { frontEnd_API, header } from '../../Config/config';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';

function UserReturn() {
    const [data, setData] = useState();

    const getOrderList = async () => {
        try {
            const { data } = await axios.put(frontEnd_API.returnorder, {}, header)
            console.log("data::", data);
            setData(data?.data);
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    useEffect(() => {
        getOrderList();
    }, [])
    return (
        <div className='ff-lexend' style={{ overflow: 'hidden' }}>
            <h4 className='p-0'>Return Orders List :</h4>
            <Row className='w-100 g-4 pt-3 custom-overflow-setting'>
                {
                    (data?.length > 0) ?
                        <Col className='col-12 custom-order-style'>
                            <Table bordered className='custom-order-table'>
                                <thead>
                                    <tr className='text-center'>
                                        <th style={{ width: "61px", minWidth: "auto" }}>Info</th>
                                        <th style={{ width: "50px", minWidth: "auto" }}>Date</th>
                                        <th>Name</th>
                                        <th>Amount</th>
                                        <th>Email</th>
                                        <th>Reason</th>
                                        <th>State</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.map((item, index) => (
                                            <tr key={index} className='fs-16 fs-md-14 fs-sm-12'>
                                                <td className='text-center flex-center-align' style={{ width: "60px", minWidth: "auto" }}>
                                                    <Link
                                                        data-tooltip-id="my-tooltip"
                                                        data-tooltip-content="More Details"
                                                        variant='success'
                                                        className='more-btn p-0'
                                                        to={`${item?.returnOrderNumber}`}
                                                    >
                                                        <TbFileInvoice />
                                                    </Link>
                                                    <Tooltip id="my-tooltip" place="top" />
                                                </td>
                                                <td className='fs-14'>{moment(item?.returnOrderDate).format('DD, MMM YYYY')}</td>
                                                <td>{item?.returnOrderPickUpName}</td>
                                                <td>₹{item?.returnOrderNetAmount}</td>
                                                <td>{item?.returnOrderPickUpEmail}</td>
                                                <td className='text-center fs-14 text-danger fw-bold'>
                                                    <Alert
                                                        className='m-0 text-center fw-bold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                                        variant={"danger"}
                                                    >
                                                        {(item?.returnOrderReason?.length > 13) ?
                                                            item?.returnOrderReason.toString().slice(0, 10) + '...'
                                                            : item?.returnOrderReason}
                                                    </Alert>
                                                </td>
                                                {/* <td className={`text-capitalize text-center`}>{item?.returnOrderShippingState}</td> */}
                                                <td className={`text-capitalize text-center`}>
                                                    <div className='justify-content-start justify-content-md-center flex-center-align flex-wrap gap-2 m-0 p-0 fs-12 fs-sm-10'>
                                                        <Alert
                                                            className='m-0 flex-center-align gap-1 pointer fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                                            variant={"info"}
                                                            data-tooltip-id="my-location"
                                                            data-tooltip-content={`${item?.returnOrderPickUpAddressLine1}, ${item?.returnOrderPickUpAddressLine2}, ${item?.returnOrderPickUpArea}, ${item?.returnOrderPickUpCity}, ${item?.returnOrderPickUpState} - ${item?.returnOrderPickUpPincode}`}
                                                        >
                                                            {(item?.returnOrderPickUpState?.length > 13) ?
                                                                item?.returnOrderPickUpState.toString().slice(0, 10) + '...'
                                                                : item?.returnOrderPickUpState
                                                            }
                                                            <i className="bi bi-info-circle-fill"></i>
                                                        </Alert>
                                                        <Tooltip id="my-location" place="top" />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Col>
                        : <Col xs='12' className='custom-order-style h-100 pt-5 d-grid justify-content-center align-items-center align-content-center text-center'>
                            <div>
                                <TbWalletOff className='no-order-label' />
                            </div>
                            <h5>No Return Orders Yet</h5>
                            <span className='fs-12 pb-3'>You have created no return orders request.</span>
                        </Col>
                }
            </Row>
        </div>
    )
}

export default UserReturn