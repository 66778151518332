import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { frontEnd_API, header } from '../../Config/config';

function Pages() {
    const params = useParams();
    const [data, setData] = useState();

    const getPageDetail = async () => {
        try {
            const { data } = await axios.get(`${frontEnd_API.getPagesList}/${params?.pageName}`, header)
            console.log("data::", data);
            setData(data?.data);
        }
        catch (e) {
            console.log("err::", e);
        }
    }

    useEffect(() => {
        getPageDetail();
    }, [params])
    return (
        <div>
            <Container>
                {
                    (data?.description) &&
                    <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
                }
            </Container>
        </div>
    )
}

export default Pages