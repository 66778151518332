import React, { useEffect, useRef, useState } from 'react'
import { Accordion, Col, Container, Dropdown, Row, Spinner } from 'react-bootstrap'
// import Slider from 'react-slick';
// import { imgList, sampVariation } from '../../Data/localData';
import axios from 'axios';
import { SERVER_URL, frontEnd_API, header } from '../../Config/config';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCart } from '../../Store/Slices/cartSlice';
import { toast, ToastContainer } from 'react-toastify';
import Slider from 'react-slick';
import ProductPreviewImage from '../../Components/ProductPreviewImageComponent';

function SingleProductPage() {

    const [productStatus, setProductStatus] = useState(0);
    const [product, setProduct] = useState();
    const [loader, setLoader] = useState(true);
    const [selectedProduct, setSelectedProduct] = useState();
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const cartData = useSelector((state) => state.cart.value);
    const [backColor, setBackColor] = useState();

    const getSingleProduct = async () => {
        try {
            const { data } = await axios.get(`${frontEnd_API?.getProductDetail}/${params?.productId}`, header)
            const productData = data?.data

            setProduct(productData);
            const check = productData?.variantData?.find((item) => ["color", "colors", "colour", "colours"].includes(item?.name?.toLowerCase()))

            productData.defaultColor = productData?.defaultColor ? check?.data?.filter(e => e?.code == productData?.defaultColor)[0]?.code : check?.data[0]?.code
            setBackColor(productData?.defaultColor)
            setProductStatus(1)
            setLoader(false);
            const updatedVariantData = productData?.variantData?.reduce((acc, variant) => {
                const firstOption = ["color", "colors", "colour", "colours"]?.includes(variant?.name) ? variant?.data?.filter(e => e?.code == productData?.defaultColor)[0] : variant?.data[0];
                acc[variant.name] = firstOption?.value;
                return acc;
            }, {});
            setSelectedProduct({
                variantData: updatedVariantData,
                productId: parseInt(productData?.productId),
                quantity: 1
            });
        }
        catch (e) {
            console.log("e::", e);
            // toast("Product not found!", { position:'top-right', className:'bg-warning text-dark', hideProgressBar:true })
            // navigate('/')
            setProductStatus(-1)
            // setLoader(false);
        }
    }

    useEffect(() => {
        getSingleProduct();
    }, [])

    const changeVariant = (obj = {}) => {
        if (obj?.name) {
            console.log("obj::", obj);
            console.log("obj?.data?.code::", obj?.data?.code);
            if (["color", "colors", "colour", "colours"].includes(obj?.name.toLowerCase())) {
                setBackColor(obj?.data?.code);
            }
            setSelectedProduct((prevData) => ({
                ...prevData,
                variantData: {
                    ...prevData.variantData,
                    [obj?.name]: obj?.data?.value
                }
            }))
        }
    }

    useEffect(() => {
        console.log("selectedProduct::", selectedProduct);
    }, [selectedProduct])

    useEffect(() => {
        console.log("cartData::", cartData);
    }, [cartData])

    const updateCartLocal = (props = {}) => {
        dispatch(updateCart(selectedProduct))
        getSingleProduct();

        toast("Product added in cart!", { position:'bottom-right', className:'bg-success text-white', hideProgressBar:true })
        if (parseInt(props?.try) === 1) {
            navigate('/cart')
        }
    }

    const priceIncrease = () => {

        const newData = (selectedProduct?.variantData) ? Object?.values(selectedProduct?.variantData) : [];

        const matchingItem = product?.cartesianData.find(item =>
            JSON.stringify(item.cartesian) === JSON.stringify(newData)
        );

        const matchingPrice = matchingItem ? matchingItem.price : null;
        const matchingMRP = matchingItem ? matchingItem.mrp : null;
        return { productPrice: matchingPrice, productMRP: matchingMRP };
    }

    const checkActive = (checkData) => {
        const preDataValues = Object.values(selectedProduct?.variantData);
        const isValueInPreData = preDataValues.includes(checkData.value);
        return isValueInPreData;
    }

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);

    useEffect(() => {
        setNav1(sliderRef1);
        setNav2(sliderRef2);
    }, []);

    const settings = {
        slidesToShow: 4,
        speed: 500
    };

    useEffect(() => {
        if (productStatus == -1) {
            toast("Product not found!", { position:'top-right', className:'bg-warning text-dark', hideProgressBar:true })
            navigate('/')
        }
    }, [productStatus])

    const handleShare = (url) => {
        const productName = product?.name;
        const productURL = `${window?.location?.href}?ref=1`;
        // const productURL = "https://xyz.printfuse.in/products/4";
        const shareMessage = `Check out this awesome find! ${productName} – get yours now: ${productURL}`;

        // WhatsApp Share URL
        const whatsappURL = `https://wa.me/?text=${encodeURIComponent(shareMessage)}`;

        // Facebook Share URL
        const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(productURL)}&quote=${encodeURIComponent(shareMessage)}`;

        // Email Share URL
        const emailSubject = `Check out this awesome product: ${productName}`;
        const emailBody = `${shareMessage}`;
        const emailURL = `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
        let link = whatsappURL;
        if (url == "whatsapp") {
            link = whatsappURL
        }
        if (url == "facebook") {
            link = facebookURL
        }
        if (url == "email") {
            link = emailURL
        }

        window.open(link, '_blank');

    };

    const { productPrice, productMRP } = priceIncrease();

    return (
        <div className='custom-product-page'>
            {
                loader ?
                    <div className='w-100 aspect-3-1 d-grid flex-center-align'>
                        <Spinner animation='border' />
                    </div>
                    : <Container className='py-4'>
                        <Row className='gy-4 gy-lg-0'>
                            <Col className='col-12 col-lg-5 ps-lg-0'>
                                <Row style={{ position: 'sticky', top: '0' }}>
                                    <Col className='col-12 col-md-0 pe-lg-0 single-big-slider'>
                                        <div className="slider-container">
                                            <Slider asNavFor={sliderRef2.current} ref={sliderRef1}>
                                                {
                                                    product?.imageData.map((item, index) => (
                                                        <div key={index} className='img-holder'>
                                                            <ProductPreviewImage colorCode={backColor ?? '#ffffff'} objectData={{ preview: item }} />
                                                        </div>
                                                    ))
                                                }
                                            </Slider>
                                            <Slider
                                                className='pt-1'
                                                asNavFor={sliderRef1.current}
                                                ref={sliderRef2}
                                                swipeToSlide={true}
                                                focusOnSelect={true}
                                                {...settings}
                                            >
                                            {
                                                product?.imageData.map((item, index) => (
                                                    <div key={index} className='img-holder cursor'>
                                                        <img src={item} alt={product?.name} style={{ backgroundColor: backColor?? 'transparent' }} />
                                                    </div>
                                                ))
                                            }
                                            </Slider>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='col-12 col-lg-7 custom-page-content ps-lg-5'>
                                <div className='d-grid custom-page-title gap-4'>
                                    <div className='flex-between-align'>
                                        <h3 className='fw-semibold m-0 first-capitalize'>{product?.name}</h3>
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic" className='p-0'>
                                                <i className="bi bi-share-fill p-2 py-1"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleShare("facebook")}>Facebook</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleShare("whatsapp")}>WhatsApp</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleShare("email")}>Email</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <h2 className='fw-semibold d-flex align-items-end gap-2 m-0'>
                                        <span>₹{productPrice}</span>
                                        <span className={`fw-semibold fs-16 text-decoration-line-through pb-1 text-secondary ${(product?.mrp > productPrice) ? "" : "d-none"}`}>₹{product?.mrp}</span>
                                        {/* <span className='fw-semibold fs-14'>Excl. Tax</span> */}
                                    </h2>
                                    {
                                        product?.variantData?.map((item, index) => {
                                            return (
                                                <div key={index}
                                                    className={`d-grid gap-1 
                                                ${(item?.name === 'size') ? 'custom-size' : ''}
                                                ${(item?.name === 'color') ? 'custom-color' : ''}`
                                                    }>
                                                    <div className='d-flex gap-1'>
                                                        <span className='p-color text-capitalize'>{item?.name}: </span>
                                                        {/* <p className='fw-semibold m-0 text-capitalize'>
                                                    {product?.variantData[index].data.label}
                                                </p> */}
                                                    </div>
                                                    {
                                                        (item?.name === "color") ?
                                                            <div className='d-flex custom-color-list gap-2'>
                                                                {
                                                                    item?.data.map((subItem, subIndex) => {
                                                                        return (
                                                                            <span
                                                                                onClick={() => changeVariant({ name: item?.name, data: subItem })}
                                                                                key={subIndex}
                                                                                className={`${(checkActive(subItem)) ? 'active' : ''}`}
                                                                                style={{ backgroundColor: subItem?.code }}
                                                                            >
                                                                            </span>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            :
                                                            <div className='d-flex custom-size-list gap-2'>
                                                                {
                                                                    item?.data.map((subItem, subIndex) => {
                                                                        return (
                                                                            <span
                                                                                onClick={() => changeVariant({ name: item?.name, data: subItem })}
                                                                                key={subIndex}
                                                                                className={`text-uppercase ${(checkActive(subItem)) ? 'active' : ''}`}
                                                                            >
                                                                                {subItem?.code}
                                                                            </span>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                    <div className='d-grid gap-1 custom-size'>
                                        <div className='d-flex gap-1'>
                                            <span className='p-color'>Quantity: </span>
                                            <p className='fw-semibold m-0 text-capitalize'>{selectedProduct?.quantity}</p>
                                        </div>
                                        <div className='d-flex custom-qty-list gap-0'>
                                            <button
                                                onClick={() => setSelectedProduct((prevData) => ({
                                                    ...prevData,
                                                    quantity: (selectedProduct?.quantity > 1) ? selectedProduct?.quantity - 1 : 1
                                                }))}
                                            >
                                                <i className="bi bi-dash-lg"></i>
                                            </button>
                                            <input type="number" value={selectedProduct?.quantity} className='text-center' readOnly />
                                            <button
                                                onClick={() => setSelectedProduct((prevData) => ({
                                                    ...prevData,
                                                    quantity: (selectedProduct?.quantity < 20) ? selectedProduct?.quantity + 1 : 20
                                                }))}
                                            >

                                                <i className="bi bi-plus-lg" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className='flex-between-align gap-2 custom-cart-btn'>
                                        <button
                                            className='fw-semibold flex-center-align gap-2'
                                            onClick={updateCartLocal}>
                                            <i className="bi bi-cart-plus-fill fs-16" />
                                            Add to Cart
                                        </button>
                                        <button className='fw-semibold' onClick={() => updateCartLocal({ try: "1" })}>Buy Now</button>
                                    </div>
                                    <div className='d-grid'>
                                        {/* <h4 className='my-2 theme-color'>About product</h4> */}
                                        <p className='fs-14 first-capitalize' dangerouslySetInnerHTML={{ __html: product?.description }}></p>
                                    </div>
                                    {/* <div className='d-grid'>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header><span className='fw-semibold theme-color fs-18'>Product details</span></Accordion.Header>
                                        <Accordion.Body className='d-flex flex-wrap'>
                                            <div className='row gy-3'>
                                                <div className='d-grid col-12 col-lg-6 align-content-start'>
                                                    <h5 className='theme-color fs-14'>Fabric</h5>
                                                    <p className='m-0 p-color fs-14'>
                                                        Made from specially spun fibers that make a very strong and smooth fabric that is perfect for printing. The "Natural" color is made with unprocessed cotton, which results in small black flecks throughout the fabric
                                                    </p>
                                                </div>
                                                <div className='d-grid col-12 col-lg-6 align-content-start'>
                                                    <h5 className='theme-color fs-14'>Without side seams</h5>
                                                    <p className='m-0 p-color fs-14'>
                                                        Knitted in one piece using tubular knit, it reduces fabric waste and makes the garment more attractive
                                                    </p>
                                                </div>
                                                <div className='d-grid col-12 col-lg-6 align-content-start'>
                                                    <h5 className='theme-color fs-14'>Ribbed knit collar without seam</h5>
                                                    <p className='m-0 p-color fs-14'>
                                                        Ribbed knit makes the collar highly elastic and helps retain its shape
                                                    </p>
                                                </div>
                                                <div className='d-grid col-12 col-lg-6 align-content-start'>
                                                    <h5 className='theme-color fs-14'>Shoulder tape</h5>
                                                    <p className='m-0 p-color fs-14'>
                                                        Twill tape covers the shoulder seams to stabilize the back of the garment and prevent stretching
                                                    </p>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><span className='fw-semibold theme-color fs-18'>Care instructions</span></Accordion.Header>
                                        <Accordion.Body className='d-grid'>
                                            <p className='p-color'>
                                                Machine wash: warm (max 40C or 105F); Non-chlorine: bleach as needed; Tumble dry: medium; Do not iron; Do not dryclean.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><span className='fw-semibold theme-color fs-18'>Shipping & delivery</span></Accordion.Header>
                                        <Accordion.Body className='d-grid'>
                                            <p className='theme-color'>
                                                Accurate shipping options will be available in checkout after entering your full address.
                                            </p>
                                            <div className='d-flex gap-2'>
                                                <div className='p-3 d-grid w-100' style={{ border: '1px solid grey', borderRadius: '.33rem' }}>
                                                    <h5 className='fw-semibold theme-color'>Premium</h5>
                                                    <div className='flex-between-align'>
                                                        <div className='d-grid w-100'>
                                                            <span className='p-color fs-14'>Cost</span>
                                                            <span className='theme-color fs-14'>from ₹199</span>
                                                        </div>
                                                        <div className='d-grid w-100'>
                                                            <span className='p-color fs-14'>Local delivery</span>
                                                            <span className='theme-color fs-14'>2-3 business days</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='p-3 d-grid w-100' style={{ border: '1px solid grey', borderRadius: '.33rem' }}>
                                                    <h5 className='fw-semibold theme-color'>Standart</h5>
                                                    <div className='flex-between-align'>
                                                        <div className='d-grid w-100'>
                                                            <span className='p-color fs-14'>Cost</span>
                                                            <span className='theme-color fs-14'>from ₹149</span>
                                                        </div>
                                                        <div className='d-grid w-100'>
                                                            <span className='p-color fs-14'>Local delivery</span>
                                                            <span className='theme-color fs-14'>4-5 business days</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header><span className='fw-semibold theme-color fs-18'>Return policy</span></Accordion.Header>
                                        <Accordion.Body className='d-grid'>
                                            <p className='p-color'>
                                                Any goods purchased can only be returned in accordance with the Terms and Conditions and Returns Policy.

                                                We want to make sure that you are satisfied with your order and we are committed to making things right in case of any issues. We will provide a solution in cases of any defects if you contact us within 7 days of receiving your order.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
            }

        </div>
    )
}

export default SingleProductPage