import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { TbWalletOff } from "react-icons/tb";
import axios from 'axios';
import { frontEnd_API, header } from '../../Config/config';
import moment from 'moment';

function UserOrder() {

    const [data, setData] = useState();

    const getOrderList = async () => {
        try {
            const { data } = await axios.put(frontEnd_API.getOrderList, {}, header)
            console.log("data::", data);
            setData(data?.data);
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    useEffect(() => {
        getOrderList();
    }, [])

    return (
        <div className='ff-lexend' style={{ overflow: 'hidden' }}>
            <h4 className='p-0'>My Orders</h4>
            <Row className='w-100 g-4 pt-3 custom-overflow-setting'>
                {
                    (data?.length > 0) ?
                        <Col className='col-12 custom-order-style'>
                            <Table bordered className='custom-order-table'>
                                <thead>
                                    <tr className=''>
                                        <th>Order No.</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        {/* <th>Customer</th> */}
                                        {/* <th>Email</th> */}
                                        <th>Status</th>
                                        <th>More</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.map((item, index) => (
                                            <tr key={index} className='fs-16 fs-md-14 fs-sm-12'>
                                                <td>{item?.orderNumber}</td>
                                                <td>{moment(item?.orderDate).format('DD, MMM YYYY')}</td>
                                                <td>₹{item?.orderGrossAmount}</td>
                                                {/* <td>{item?.orderBillingName}</td> */}
                                                {/* <td>{item?.orderShippingEmail}</td> */}
                                                <td className={`fw-semibold text-capitalize`}>{item?.orderStatus}</td>
                                                <td className='text-center'>
                                                    <Link className='py-1 px-2 fs-14 fw-semibold' to={`${item?.orderNumber}`}>More Details</Link>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Col>
                        : <Col xs='12' className='custom-order-style h-100 d-grid justify-content-center align-items-center align-content-center text-center'>
                            <div>
                                <TbWalletOff className='no-order-label' />
                            </div>
                            <h5>No Orders Yet</h5>
                            <span className='fs-12 pb-3'>You have placed no orders.</span>
                            <Link to='/' className='custom-explore-btn text-uppercase halfBorderRad px-3 py-2 fs-16'>
                                Start Explore
                            </Link>
                        </Col>
                }
            </Row>
        </div>
    )
}

export default UserOrder
