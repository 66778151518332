import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Accordion, Alert, Button, Card, Col, Container, Dropdown, Modal, Row } from 'react-bootstrap'
import { frontEnd_API, header } from '../../Config/config'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import ProductPreviewImage from '../../Components/ProductPreviewImageComponent'
import Swal from 'sweetalert2'
import { MdDescription } from 'react-icons/md'
import { BsInfoCircleFill } from 'react-icons/bs'

function UserSingleReturn() {
    const params = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState();
    const [user, setUser] = useState();

    const getUser = async () => {
        try {
            const { data } = await axios.get(`${frontEnd_API.getProfile}`, header)
            console.log("Data::", data);
            setUser(data?.data);
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    const getReturnDetail = async () => {
        try {
            const { data } = await axios.get(`${frontEnd_API?.returnorder}/${params?.returnOrderNumber}`, header)
            console.log("Data::", data);
            setData(data?.data);
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    useEffect(() => {
        getReturnDetail();
        getUser();
    }, [])

    return (
        <div className='custom-order-detail'>
            <Container>
                <Row className='gy-3'>
                    <Col className='col-12 flex-between-align gap-3 custom-order-detail-title'>
                        <div className='d-flex gap-1 gap-md-3 align-items-start flex-wrap'>
                            <div className='d-grid gap-1 gap-md-2'>
                                <h4 className='fw-semibold fs-md-20 fs-sm-16 m-0'>Return Order No: {data?.returnOrderNumber}</h4>
                                <h6 className='fs-sm-12'>{moment(data?.returnOrderDate).format('DD, MMM YYYY')}</h6>
                                <div onClick={() => navigate(`/user/orders/${data?.orderNumber}`)} className='pointer px-2 py-1 border border-2 rounded-2 border-success fw-bold fs-12' style={{width:'max-content'}}>{data?.orderNumber}</div>
                            </div>
                            <span className='custom-order-detail-title-status mt-md-2 py-1 px-2 fw-semibold fs-14 fs-sm-12'>{data?.returnOrderStatus}</span>
                        </div>
                    </Col>
                    <Col className='col-12 col-md-9 d-grid custom-order-detail-list align-content-start'>
                        <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen className='d-grid gap-3'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><span className='fw-semibold fs-18 fs-md-16 fs-sm-14'>Return Order Items</span></Accordion.Header>
                                <Accordion.Body className='d-grid gap-3'>
                                    {
                                        (data?.returnOrderChildData?.length > 0) &&
                                        data?.returnOrderChildData?.map((item, index) => {
                                            const colorCode = item?.childOrderVariantData?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.parentName))?.map(e => e?.variantCode)
                                            return (
                                                <>
                                                    <Row key={index}>
                                                        <Col className='col-4 px-1 px-sm-2 col-sm-3 col-md-2 d-grid'>
                                                            <div className='img-container'>
                                                                <ProductPreviewImage
                                                                    colorCode={colorCode ?? '#ffffff'}
                                                                    isButtonShow={false}
                                                                    objectData={{
                                                                        preview: item?.productThumbnail || null,
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col className='col-8 px-1 px-sm-2 col-sm-9 col-md-10 d-grid'>
                                                            <div className='d-grid gap-2 gap-sm-3 align-content-between'>
                                                                <div className='d-grid align-content-around gap-1 gap-sm-2 gap-lg-2'>
                                                                    <h5 className='fw-semibold fs-md-14 fs-sm-12 m-0'>{item?.productName}</h5>
                                                                    <div className='d-flex flex-wrap gap-2'>
                                                                        {
                                                                            (item?.childOrderVariantData?.length > 0) &&
                                                                            item?.childOrderVariantData?.map((subItem, subIndex) => (
                                                                                <span
                                                                                    index={subIndex}
                                                                                    className='custom-order-detail-title-status px-2 fs-14 fs-sm-10 fw-semibold text-uppercase'
                                                                                >
                                                                                    {subItem?.variantName}
                                                                                </span>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex align-items-center flex-wrap gap-2 gap-sm-3'>
                                                                    <span className='custom-order-detail-title-status px-2 px-sm-3 py-sm-1 fs-14 fs-sm-12 fw-medium'>{item?.productQuantity} X ₹{parseInt(item?.productPrice)}</span>
                                                                    <span className='custom-order-detail-title-status px-2 px-sm-3 py-sm-1 fs-14 fs-sm-12 fw-bold'>₹{parseInt(item?.productSubTotal)}</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr className={`m-0 ${((data?.returnOrderChildData?.length - 1) === index) ? "d-none" : ''}`} />
                                                </>
                                            )
                                        })
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span className='fw-semibold fs-18 fs-md-16 fs-sm-14'>Order Summary</span></Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col className='col-12 d-grid gap-2 pb-2 pb-sm-3'>
                                            <div className='d-flex gap-2 flex-wrap'>
                                                <span className='custom-order-detail-title-status px-2 px-sm-3 py-sm-1 fs-14 fs-sm-12 fw-bold text-uppercase'>
                                                    {(data?.returnOrderPaymentModeName !== "COD") ? `${data?.returnOrderPaymentModeName}` : 'COD'}
                                                </span>
                                                {(data?.orderPaymentMode !== "COD") &&
                                                    <span className='custom-order-detail-title-status px-2 px-sm-3 py-sm-1 fs-14 fs-sm-12 fw-semibold'>
                                                        Trasaction ID: {data?.returnOrderPaymentTransactionNumber}
                                                    </span>
                                                }
                                            </div>
                                            <p className='m-0'>List about order summary :</p>
                                        </Col>
                                        <Col className='col-12 col-lg-7 d-grid'>
                                            <div>SubTotal</div>
                                        </Col>
                                        <Col className='col-12 col-lg-5 d-grid'>
                                            <div className='flex-between'>
                                                <span>{data?.returnOrderChildData?.length} item</span>
                                                <span>₹{data?.returnOrderSubTotalAmount}</span>
                                            </div>
                                        </Col>
                                        <Col className='col-12 col-lg-7 d-grid'>
                                            <div>Discount</div>
                                        </Col>
                                        <Col className='col-12 col-lg-5 d-grid'>
                                            <div className='flex-between'>
                                                <span>{`${data?.couponCode ? `(${data?.couponCode})` : `-`}`}</span>
                                                <span>- ₹{data?.returnOrderCouponAmount}</span>
                                            </div>
                                        </Col>
                                        <Col className='col-12 col-lg-7 d-grid'>
                                            <div>Shipping</div>
                                        </Col>
                                        <Col className='col-12 col-lg-5 d-grid'>
                                            <div className='flex-between'>
                                                <span>{(parseInt(data?.returnOrderShippingChargeDiscountAmount) === 0) ? "Free Shipping" : ""}</span>
                                                <span>- <span className='text-decoration-line-through'>₹{data?.returnOrderShippingChargeDiscountAmount}</span></span>
                                            </div>
                                        </Col>
                                        <div className='px-2'>
                                            <hr className='my-2' />
                                        </div>
                                        <Col className='col-7 d-grid'>
                                            <div className='fw-bold fs-18'>Total</div>
                                        </Col>
                                        <Col className='col-5 d-grid'>
                                            <div className='flex-between justify-content-end'>
                                                <span className='fw-bold fs-18'>₹{data?.returnOrderNetAmount}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><span className='fw-semibold fs-18 fs-md-16 fs-sm-14'>Return Order Tracking</span></Accordion.Header>
                                <Accordion.Body>
                                    {
                                        (data?.returnOrderTrackingData) &&
                                        <div className='timeline'>
                                            {
                                                (data?.returnOrderTrackingData?.length > 0) &&
                                                data?.returnOrderTrackingData.map((item, index) => {
                                                    return (
                                                        <div key={index} className={`timeline-item ${((data?.returnOrderTrackingData?.length - 1) === index) ? 'lastItem' : ''}`}>
                                                            <div
                                                                className={`timeline-icon circle-one mt-1 ms-1`}
                                                                style={{
                                                                    backgroundColor: (index === 0) ? 'darkblue' : 'grey',
                                                                    outlineColor: (index === 0) ? 'darkblue' : 'grey',
                                                                }}
                                                            ></div>
                                                            <div className='timeline-content'>
                                                                <span className='timestamp'>{moment(item?.updatedAt).format('DD, MMM YYYY h:mm A')}</span>
                                                                <div>
                                                                    <b className='text-capitalize'>{item?.trackingStatus}</b>
                                                                    <span className='description'>{item?.trackingRemark}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col className='col-12 col-md-3 d-grid align-items-start ps-md-0'>
                        <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen className='d-grid gap-3'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><span className='fw-semibold fs-18 fs-md-16 fs-sm-14'>Return Reason</span></Accordion.Header>
                                <Accordion.Body className='d-grid pt-1 gap-2'>
                                    <Alert
                                        className=' fs-14 m-0 text-center fw-bold pb-1 fs-lg-16 fs-18 text-capitalize py-1 px-2 px-sm-3'
                                        variant={"danger"}
                                    >
                                        {data?.returnOrderReasonData?.reasonName}
                                    </Alert>
                                    <hr className='my-1 mt-2'/>
                                    <div className='d-flex gap-2'>
                                        <BsInfoCircleFill className='fs-20 mt-1'/>
                                        <p className='m-0'>{data?.returnOrderReasonData?.descritpion}</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span className='fw-semibold fs-18 fs-md-16 fs-sm-14'>Customer</span></Accordion.Header>
                                <Accordion.Body className='d-grid pt-1'>
                                    <div className='d-flex gap-2 fw-semibold fs-sm-14'>
                                        <i className="bi bi-person-circle" />
                                        <span className='text-capitalize'>{user?.name}</span>
                                    </div>
                                    <div className='d-flex gap-2 fw-semibold fs-sm-14'>
                                        <i className="bi bi-envelope" />
                                        <span className=''>{user?.email}</span>
                                    </div>
                                    <div className='d-flex gap-2 fw-semibold fs-sm-14'>
                                        <i className="bi bi-telephone-fill" />
                                        <span className='text-capitalize'>{user?.mobile}</span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><span className='fw-semibold fs-18 fs-md-16 fs-sm-14'>Pickup Address</span></Accordion.Header>
                                <Accordion.Body className='d-grid gap-1 gap-sm-2 pt-1'>
                                    <div className='d-flex gap-2 fs-sm-14'>
                                        <i className="bi bi-person-circle" />
                                        <span className='text-capitalize'>{data?.returnOrderPickUpName}</span>
                                    </div>
                                    <div className='d-flex gap-2 fs-sm-14'>
                                        <i className="bi bi-envelope" />
                                        <span>{data?.returnOrderPickUpEmail}</span>
                                    </div>
                                    <div className='d-flex gap-2 fs-sm-14'>
                                        <i className="bi bi-telephone-fill" />
                                        <span className='text-capitalize'>{data?.returnOrderPickUpMobile}</span>
                                    </div>
                                    <div className='d-flex gap-2'>
                                        <i className="bi bi-geo-alt-fill" />
                                        <p className='m-0 fs-14 fs-sm-12'>{data?.returnOrderPickUpAddressLine1}, {data?.returnOrderPickUpAddressLine2}, {data?.returnOrderPickUpArea}, {data?.returnOrderPickUpCity}, {data?.returnOrderPickUpState}, {data?.returnOrderPickUpCountry} - {data?.returnOrderPickUpPincode}</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* <Accordion.Item eventKey="3">
                                <Accordion.Header><span className='fw-semibold fs-18 fs-md-16 fs-sm-14'>Billing Address</span></Accordion.Header>
                                <Accordion.Body className='d-grid gap-1 gap-sm-2 pt-1'>
                                    <div className='d-flex gap-2 fs-sm-14'>
                                        <i className="bi bi-person-circle" />
                                        <span className='text-capitalize'>{data?.returnOrderPickUpName}</span>
                                    </div>
                                    <div className='d-flex gap-2 fs-sm-14'>
                                        <i className="bi bi-envelope" />
                                        <span>{data?.returnOrderPickUpEmail}</span>
                                    </div>
                                    <div className='d-flex gap-2 fs-sm-14'>
                                        <i className="bi bi-telephone-fill" />
                                        <span className='text-capitalize'>{data?.returnOrderPickUpMobile}</span>
                                    </div>
                                    <div className='d-flex gap-2'>
                                        <i className="bi bi-geo-alt-fill" />
                                        <p className='m-0 fs-14 fs-sm-12'>{data?.returnOrderPickUpAddressLine1}, {data?.returnOrderPickUpAddressLine2}, {data?.returnOrderPickUpArea}, {data?.returnOrderPickUpCity}, {data?.returnOrderPickUpState}, {data?.returnOrderPickUpCountry} - {data?.returnOrderPickUpPincode}</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item> */}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default UserSingleReturn